<template>

  <div>

    <StockModals
      ref="stockModals"
      :type="actionType"
      :branch-type="branchType"
      :default-value="defaultValue"
      :is-minus="isMinus"
      :is-still="isStill"
      @onStockInAndOut="onStockInAndOut"
      @onTransferFromBranchToBranch="onTransferFromBranchToBranch"
    />

    <add-edit-item-page
      ref="addEditItemPageRef"
      :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
      :is-edit="isEdit"
      :is-shop="true"
    />

    <item-history-page
      ref="itemHistoryPageRef"
      :is-item-history-sidebar-active.sync="isItemHistorySidebarActive"
    />

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Filter</label>
            <v-select
              v-model="filterBySelected"
              :options="filterOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>

            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Sort</label>
            <!-- style="min-width: 200px !important;max-width: 200px !important;" -->
            <v-select
              v-model="sortBySelected"
              :options="sortOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>

            </v-select>
          </b-col>
          <b-col
            v-if="isAllowedToSeeAllBranches"
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Branch</label>

            <CollectionSelect
              ref="collectionRef"
              class="per-page-selector d-inline-block mx-50"
              tablename="branch"
              :branch-type="branchTypeField.store"
              @selectedData="onSelectedBranch"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-overlay
      id="overlay-background"
      :show="secondaryLoading || loading"
      variant="blur"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="8"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="ShuffleIcon"
                    class="cursor-pointer"
                    @click="changeToAlphabetically"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-col>
            <b-col
              cols="4"
            >

              <b-button
                v-if="userAllowedClass.isAddStore(userAllowedList)"
                variant="gradient-primary"
                @click="handleAddNewItem"
              >
                Add item to Shop
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refItemListTable"
          v-model="itemsListCopy"
          :per-page="7"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          :busy="loading"
          primary-key="id"
          :sort-by.sync="sortBy"
          striped
          :show-empty="!loading"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: User -->
          <template #cell(image)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="80"
                  rounded="sm"
                  variant="light-primary"
                  :src="getImage(data.item.image)"
                />
              </template>
            </b-media>
          </template>

          <template #cell(qty)="data">
            <span
              class="text-nowrap"
              :class="data.item.qty <= 0 ? 'text-danger' : ''"
            >
              {{ data.item.qty }}
            </span>
          </template>

          <template #row-details="data">
            <b-card>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Item code : </strong>{{ data.item.itemCode }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Branch : </strong>{{ data.item.branchName }}
                </b-col>
                <b-col
                  v-if="data.item.createdAt && data.item.createdAt.length >= 15"
                  md="4"
                  class="mb-1"
                >
                  <strong>Created At : </strong>{{ data.item.createdAt.slice(0, 15) }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Unit price With {{ taxLabelWithPercentage }} : </strong>{{ calcVatFn(data.item.unitPrice).toFixed(2) }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Minimum Price : </strong>{{ data.item.minPrice }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Maximum Price : </strong>{{ data.item.maxPrice }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Description : </strong>{{ data.item.desc }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Expire date : </strong>{{ data.item.expireDate }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Brand : </strong>{{ data.item.brand }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Group : </strong>{{ data.item.group }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Group : </strong>{{ data.item.mark }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Location : </strong>{{ data.item.location }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Manufacturer : </strong>{{ data.item.manufacturer }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Manufactured date : </strong>{{ data.item.manufacturerDate }}
                </b-col>
                <b-col
                  v-if="data.item.createdAt && data.item.createdAt.length >= 15"
                  md="4"
                  class="mb-1"
                >
                  <strong>Created At : </strong>{{ data.item.createdAt.slice(0, 15) }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Measurement : </strong>{{ data.item.measurement }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Status : </strong>{{ data.item.status }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Invoice No : </strong>{{ data.item.invoiceNo }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Invoice Date : </strong>{{ data.item.invoiceDate }}
                </b-col>
              </b-row>
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="data.toggleDetails"
              >
                Hide Details
              </b-button>
            </b-card>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <span @click="data.toggleDetails">
                <feather-icon
                  :id="`invoice-row-${data.item.id}-preview-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="mx-1"
                />

              </span>
              <b-tooltip
                title="Preview Detail"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
              <!-- @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})" -->
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- :to="{ name: 'apps-users-view', params: { id: data.item.id } }" -->

                <b-dropdown-item
                  v-if="userAllowedClass.isEditStore(userAllowedList)"
                  @click="handleEditItem(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userAllowedClass.isStockInAllowed(userAllowedList)"
                  @click="stockIn(data.item)"
                >
                  <feather-icon icon="LogInIcon" />
                  <span class="align-middle ml-50">Stock In</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="userAllowedClass.isStockOutAllowed(userAllowedList)"
                  @click="stockOut(data.item)"
                >

                  <feather-icon
                    icon="LogOutIcon"
                    color="#EF6142"
                  />
                  <span class="align-middle ml-50">Stock Out</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="userAllowedClass.isTransferStore(userAllowedList)"
                  @click="transferFromBranchToBranch(data.item)"
                >
                  <feather-icon
                    icon="LogOutIcon"
                    color="#f0ad4e"
                  />
                  <span class="align-middle ml-50">Transfer to branch</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="userAllowedClass.isDeleteStore(userAllowedList)"
                  variant="danger"
                  @click="onDelete(data.item)"
                >

                  <feather-icon
                    icon="TrashIcon"
                    color="danger"
                  />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAvatar, BButton, BCard, BCardBody, BCardHeader, BCol,
  // BBadge,
  BDropdown, BDropdownItem, BFormInput, BInputGroup, BInputGroupAppend, BMedia,
  // BLink,
  BOverlay, BPagination, BRow, BTable, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import {
  branchModelField, branchTypeField, historyModelField, historyTypeField, itemHistoriesModelField, itemModelField, itemStatus, itemsTypeEnum, mysqlCommonModel,
} from '@/api/field_model'
import {
  Ajax,
  getApiData, getWebData, isApiSuccess, RouteConst,
} from '@/libs/ajax'
import AuthMixin from '@/mixins/authMixin'
import { mapState } from 'vuex'
import CollectionSelect from '../collections/CollectionSelect.vue'
import ItemHistoryPage from '../itemHistory/itemHistoryPage.vue'
import AddEditItemPage from '../addEditItem/addEditItemPage.vue'
// import AddNewItem from './AddNewItem.vue'
import {
  debounce, handleCurrentDate, isDef, noImage, perPage,
} from '../../assets/helpers'
import StockModals from '../../components/StockModals.vue'
import ItemsRepository from '../../repository/items_repository'

const itemsRepository = new ItemsRepository()
export default {
  components: {

    BCard,
    BRow,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCol,
    BFormInput,
    BTooltip,
    BButton,
    BCardBody,
    BCardHeader,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    StockModals,
    AddEditItemPage,
    ItemHistoryPage,
    CollectionSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  data() {
    return {
      currentPage: 1,
      historyTypeField,
      isEdit: false,
      branchType: null,
      actionType: null,
      branchTypeField,
      tableColumns: [
        { key: 'Image' },
        { key: 'name', sortable: true },
        { key: 'partNo', sortable: false },
        // {
        //   key: 'currentPlan',
        //   label: 'Plan',
        //   formatter: title,
        //   sortable: true,
        // },
        { key: 'unitPrice', sortable: true },
        { key: 'qty', sortable: false },
        { key: 'branchName' },
        { key: 'actions' },
      ],
      defaultValue: 0,
      isMinus: false,
      isStill: false,
      isItemHistorySidebarActive: false,
      editableId: null,
      sortBy: 'id',
      filterBySelected: {
        label: 'All Items',
        technicalName: '',
        value: '',
      },
      sortBySelected: {
        label: 'Date Added (New to Old)',
        technicalName: mysqlCommonModel.createdAt,
        value: 'DESC',
      },
      isSortDirDesc: true,
      filterByBranch: null,
      perPage,
      isAddNewItemSidebarActive: false,
      alphabetically: false,
      searchQuery: '',
      loading: false,
      secondaryLoading: false,
      itemModelEditable: 0,
      total: 0,
      filterOptions: [
        {
          label: 'All Items',
          technicalName: '',
          value: '',
        },
        {
          label: 'Active Items',
          technicalName: itemModelField.status,
          value: `= "${itemStatus.available}"`,
        },
        {
          label: 'Inactive Items',
          technicalName: itemModelField.status,
          value: `= "${itemStatus.unAvailable}"`,
        },
        // {
        //   label: 'Low Warehouse items',
        //   technicalName: itemModelField.qty,
        //   value: `<= ${this.minStockQty}`,
        // },
      ],
      sortOptions: [
        {
          label: 'Date Added (New to Old)',
          technicalName: mysqlCommonModel.createdAt,
          value: 'DESC',
        },
        {
          label: 'Price (Low to High)',
          technicalName: itemModelField.unitPrice,
          value: 'ASC',
        },
        {
          label: 'Price (High to Low)',
          technicalName: itemModelField.unitPrice,
          value: 'DESC',
        },
      ],
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ],
      itemsListCopy: [],
    }
  },
  computed: {
    ...mapState({
      itemsFromState: state => state.items.stockItems,
    }),
    refItemListTable() {
      return this.$refs.refItemListTable
    },
    from() {
      return this.perPage * (this.currentPage - 1) + (this.itemsListCopy.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.itemsListCopy.length
    },
    of() {
      return this.total
    },
  },
  watch: {
    itemsFromState(newData) {
      console.debug('itemsFromState', newData)
      this.$refs.refItemListTable.localItems = newData
    },
    sortBySelected() {
      this.refItemListTable.refresh()
    },
    filterBySelected() {
      this.refItemListTable.refresh()
    },
    searchQuery: debounce((function func(val) {
      const thiss = this
      if (val) {
        // console.debug(this)
        thiss.searchItems({ refresh: true })
      } else {
        thiss.refItemListTable.refresh()
      }
    }), 500),
    currentPage() {
      if (this.searchQuery !== '') {
        this.searchItems({})
      } else {
        this.refItemListTable.refresh()
      }
    },
  },
  methods: {
    onSelectedBranch(v) {
      this.filterByBranch = v.id
      this.refItemListTable.refresh()
    },
    changeToAlphabetically() {
      this.alphabetically = !this.alphabetically
      if (this.searchQuery !== '') {
        this.searchItems({ refresh: true })
      }
    },
    getImage(v) {
      return isDef(v) && v !== '' ? v : noImage
    },
    handleAddNewItem() {
      this.isEdit = false
      this.isAddNewItemSidebarActive = true
    },
    handleEditItem(v) {
      this.isEdit = true
      this.$refs.addEditItemPageRef.itemModel = { ...v }
      const status = isDef(v[itemModelField.status]) ? v[itemModelField.status] : 0
      this.$refs.addEditItemPageRef.itemModel.status = {
        value: Number(status),
        label: Number(status) === 0 ? 'Available' : 'UnAvailable',
      }
      this.$refs.addEditItemPageRef.purchasePrice = v.purchasePrice
      this.$refs.addEditItemPageRef.unitPrice = v.unitPrice
      this.$refs.addEditItemPageRef.expireDate = v.expireDate
      this.$refs.addEditItemPageRef.reset()

      this.isAddNewItemSidebarActive = true
    },
    showSuccessMessage(message) {
      this.$swal({
        position: 'bottom-end',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onStockInAndOut({
      qty, supplierId, purchasePrice, expiryDate, note,
    }) {
      if (this.isMinus) { // stock out
        this.itemModelEditable = Number(this.defaultValue - qty)
        this.handleStockOutFn(qty)
      } else {
        this.itemModelEditable = Number(this.defaultValue + qty)
        this.handleStockInFn(qty, supplierId, purchasePrice, expiryDate, note)
      }
    },
    async onTransferFromBranchToBranch({ qty, branch }) {
      this.secondaryLoading = true
      const adminId = this.userData.id
      let type = ''
      const branchId = branch.id
      const branchType = branch[branchModelField.type]

      const itemModel = this.refItemListTable.localItems.find(v => v.id === this.editableId)
      const itemId = itemModel[mysqlCommonModel.id]
      const itemModelBranchType = itemModel[itemModelField.branchType]

      console.log({ itemModel, branch, itemModelBranchType })

      if ((itemModelBranchType === branchTypeField.stock && branchType === branchTypeField.stock) || (itemModelBranchType === branchTypeField.store && branchType === branchTypeField.store)) {
        type = historyTypeField.transferFromBranchToBranch
      }

      if (itemModelBranchType === branchTypeField.stock
        && branchType === branchTypeField.store) {
        type = historyTypeField.transferFromStockToStore
      }

      if (itemModelBranchType === branchTypeField.store
        && branchType === branchTypeField.stock) {
        type = historyTypeField.transferFromStoreToStock
      }

      const historyModel = {
        [historyModelField.type]: type,
        [historyModelField.item]: `${itemModel[itemModelField.name]}`,
        itemId,
        [historyModelField.originalId]: itemModel[itemModelField.originalId],
        [mysqlCommonModel.createdAt]: handleCurrentDate(),
        [historyModelField.result]:
        `${itemModel[itemModelField.branchName]} to ${branch[branchModelField.name]}, ${qty}`,
        [mysqlCommonModel.branchId]: branchId,
      }
      const res = await Ajax(RouteConst.transferItemFromBranchToBranchUrl, {
        body: {
          branchId,
          item: itemModel,
          adminId,
          qty,
          historyModel,
        },
      })
      const itemModelCopy = { ...itemModel }
      itemModelCopy[itemModelField.qty] = itemModel[itemModelField.qty] - qty
      if (isApiSuccess(res)) {
        this.$store.commit('items/updateSingleItem', {
          itemModel: itemModelCopy,
          itemsType: itemsTypeEnum.stock,
        })
        this.showSuccessMessage('Successfully transfer From Shop')
      }
      this.secondaryLoading = false
    },
    async handleStockOutFn(qty) {
      this.secondaryLoading = true
      const res = await new Ajax(RouteConst.stockOutItemUrl, {
        id: this.editableId,
        key: itemModelField.qty,
        value: qty,
      })
      if (isApiSuccess(res)) {
        const itemModel = this.refItemListTable.localItems.find(v => v.id === this.editableId)
        this.historyModelData.type = historyTypeField.stockOut
        this.historyModelData.item = itemModel.name
        this.historyModelData.oId = itemModel.oId
        this.historyModelData.branchId = null
        this.historyModelData.supplierId = null
        this.historyModelData.result = `${Number(this.itemModelEditable + qty)} to ${this.itemModelEditable}`
        this.handleAddToHistory()
        itemModel.qty = this.itemModelEditable
        this.showSuccessMessage('Successfully Stock Out')
      }
      this.secondaryLoading = false
    },
    async handleStockInFn(qty, supplierId, purchasePrice, expiryDate, note) {
      this.secondaryLoading = true
      const qtyToBeAdded = this.itemModelEditable
      const itemModel = this.refItemListTable.localItems.find(v => v.id === this.editableId)

      const itemHistory = {
        [itemHistoriesModelField.purchasePrice]: purchasePrice,
        [itemHistoriesModelField.expireDate]: expiryDate,
        [mysqlCommonModel.supplierId]: supplierId,
        [itemHistoriesModelField.qty]: qty,
        [itemHistoriesModelField.note]: note,
        [itemHistoriesModelField.itemId]: itemModel.id,
        [mysqlCommonModel.createdAt]: new Date(Date.now()), // TODO
        [mysqlCommonModel.adminId]: null,
      }

      const historyModel = {
        type: historyTypeField.stockIn,
        item: itemModel.name,
        itemId: itemModel.id,
        originalId: itemModel.oId,
        branchId: null,
        supplierId,
        result: `${this.defaultValue} to ${qtyToBeAdded}`,
        note,
      }

      const res = await Ajax(RouteConst.stockInItemUrl, {
        body: {
          itemHistory,
          historyModel,
        },
      })

      if (isApiSuccess(res)) {
        this.showSuccessMessage('Successfully Stock In')
        itemModel.qty = qtyToBeAdded
      }
      this.secondaryLoading = false
    },
    stockIn(v) {
      this.editableId = v.id
      this.isMinus = false
      this.actionType = historyTypeField.stockIn
      this.defaultValue = v.qty
      this.$refs.stockModals.$refs['stock-in-modal'].show()
    },
    transferFromBranchToBranch(v) {
      this.editableId = v.id
      this.isMinus = true
      this.actionType = historyTypeField.transferFromBranchToBranch
      this.defaultValue = v.qty
      this.branchType = branchModelField.stock
      this.$refs.stockModals.$refs['stock-in-modal'].show()
    },
    stockOut(v) {
      this.editableId = v.id
      this.isMinus = true
      this.actionType = historyTypeField.stockOut
      this.defaultValue = v.qty
      // this.isAddNewItemSidebarActive = true
      this.$refs.itemHistoryPageRef.itemModel = { ...v }
      this.$refs.itemHistoryPageRef.isStockOut = true

      this.$refs.itemHistoryPageRef.getItemHistories()
      this.isItemHistorySidebarActive = true
      // this.$refs.stockModals.$refs['stock-in-modal'].show()
    },
    onDelete(v) {
      // console.debug(v)
      this.$swal({
        title: 'Sure to delete?',
        text: `Deleting item "${v.name}" will be historied.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        // console.debug(result)
        if (result.value) { // isConfirmed
          this.handleDelete(v)
        }
      })
    },
    async handleDelete(v) {
      this.secondaryLoading = true
      try {
        const res = await new Ajax(RouteConst.deleteItemUrl, {
          body: {
            id: v.id,
            historyModel: {
              type: v.branchType === branchTypeField.stock
                ? historyTypeField.deleteStockItem
                : historyTypeField.deleteStoreItem,
              item: `${v.name}`,
              originalId: v.originalId,
              itemId: v.id,
              result: '',
            },
          },
        })
        this.secondaryLoading = false
        if (isApiSuccess(res)) {
          this.$store.commit('items/deleteSingleItem', {
            itemsType: itemsTypeEnum.stock,
            itemModel: v,
          })
          this.showSuccessMessage(`Item ${v.name} has been deleted.`)
          const index = this.refItemListTable.localItems.indexOf(v)
          this.refItemListTable.localItems.splice(index, 1)
        }
      } catch (error) {
        this.secondaryLoading = false
        this.showToast('Fail', 'Fail to delete item')
      }
    },
    async searchItems({ refresh = false }) {
      this.loading = true
      const res = await itemsRepository.getPaginatedSearchItems(
        refresh ? 1 : this.currentPage,
        this.searchQuery,
        false,
        this.alphabetically,
        null,
        {
          isShop: true,
          customBranchId: this.filterByBranch,
        },
      )
      this.loading = false
      if (isApiSuccess(res)) {
        const originalResult = getApiData(res)
        const webData = getWebData(res)
        this.$refs.refItemListTable.localItems = originalResult
        this.total = webData.sqlCount
        this.$store.commit('items/addBulkItems', { items: this.itemsListCopy, itemsType: itemsTypeEnum.stock })
      }
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    fetchItems(ctx, callback) {
      console.debug(ctx)
      this.loading = true
      const filterByBranchW = this.filterByBranch ? {
        technicalName: itemModelField.branchId,
        value: `= "${this.filterByBranch}"`,
      } : null
      const filterBySelectedW = this.filterBySelected.label !== 'All Items' ? this.filterBySelected : null

      itemsRepository.getPaginatedItems(
        this.currentPage,
        filterBySelectedW || filterByBranchW ? [filterBySelectedW, filterByBranchW] : null,
        this.sortBy !== 'id' ? {
          technicalName: ctx.sortBy,
          value: ctx.sortDesc ? 'DESC' : 'ASC',
        } : this.sortBySelected,
        {
          isShop: true,
        },
      ).then(res => {
        this.loading = false
        const originalResult = getApiData(res)
        const webData = getWebData(res)
        callback(originalResult)
        this.total = webData.sqlCount
        this.$store.commit('items/addBulkItems', { items: originalResult, itemsType: itemsTypeEnum.stock })
      })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching stock list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            timeout: 4000,
            position: 'bottom-right',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  min-width: 240px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
